#login {
  background-image: url("../assets/backgrounds/backgroundLogin.jpg");
  background-position: center;
  height: 100vh;
  background-size: cover;
}

#login h1{
    font-size: 40px;
}

.contentLogin {
  background-color: rgba(160, 160, 160, 0.5);
  height: 100vh;
  text-align: center;
  padding: 30px;
  display: grid;
  align-content: center;  
}

.help {
  margin: 40px 0;
}

.bottom {
  position: absolute;
  bottom: 0;
  padding: 0 50px;
}

.bottom span {
  font-size: 11px;
}


@media (max-width: 768px){
  .contentLogin {
    background-color: rgba(40, 40, 40, 0.9);
    color: #fff;
  }
  #login h1{
    color: #fff;
  }
  .contentLogin .btn-secondary{
    border-color: #fff;
    color: #fff;
  }
}
#formRequestContainer{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
#formRequestContainer > *{
    width: 100%;
}
#formRequest{
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 6%);
    border: solid 1px#ececef;
    background-color: #fff;
    transition: all ease-in 0.2s;
    margin: 0 auto 30px 0;
}
#formRequest h3{
    font-family: "Gotham-Bold", sans-serif;
    font-size: 45px;
    font-weight: 700;
    letter-spacing: -1px;
}
#formRequest .step{
    display: grid;
    justify-items: center;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

label.dzu-inputLabel {
    font-family: "Mulish";
    font-size: 16px;
    font-weight: normal;
    color: #444;
}

.dzu-inputLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: 'Helvetica', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #2484FF;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
}

.dzu-dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    transition: all .15s linear;
    border-radius: 2px;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.2);
    overflow: hidden ;
    background-color: #e9ecef;
    min-height: 60px !important;
}
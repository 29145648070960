#licencias {
    background-color: #f2f2f3;
    color: var(--font-color);
    background-image: var(--backgroundImage);
    background-repeat: no-repeat, no-repeat;
    font-family: var(--main-font);
    min-height: 100vh;
    padding-top: 20px;
    background-size: cover;
    background-attachment: fixed;
}
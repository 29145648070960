@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;700&family=Red+Hat+Display:wght@400;700;900&display=swap");

/*Dark Mode Fonts*/
@import url("https://fonts.googleapis.com/css2?family=Metal+Mania&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Metamorphous&display=swap");

/*Christmas Mode Fonts*/

@import url("https://fonts.googleapis.com/css2?family=Mali:wght@500&display=swap");

@font-face {
  font-family: "Gotham-Bold";
  src: url("../assets/Gotham-Font/Gotham-Bold.otf");
}

@font-face {
  font-family: "Din";
  src:url("../assets/fonts/DNC47.otf")
}

@font-face {
  font-family: "Gotham";
  src: url("../assets/Gotham-Font/Gotham-Regular.otf");
}

/*Identificadores de Ambientes*/
.warning {
  text-align: center;
  font-size: 10px;
  position: fixed;
  right: 0;
  top: 0;
  padding: 5px 10px;
  z-index: 99;
}

.dev {
  background-color: crimson;
  color: #fff;
}

.staging {
  background-color: darkgoldenrod;
  color: #fff;
}

a {
  text-decoration: none;
}

:root {
  /*Colores*/
  --yellow-possumus: #fccf00;
  --color-black: #2e2d31;

  --grey-backgrounds: #3a3a3a;
  /*Transiciones*/

  --transition-3s: all 0.3s ease-out;
}

/*Preloaders*/

#fullLoader {
  padding-top: 20%;
}

.Pulsar-module_container__iLGP9 {
  margin: 0 auto;
}

/* Loading */
.loading{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading img{
  width: 50px;
}
:root {

    --black: #2E2D31;
    --grey: rgba(241, 248, 250, 1);
}


#politicas {
    background-image: url(../assets/policy/BAckGround.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: auto;
    background-attachment: fixed;
}



h1 {
    font-size: 68px;
    color: var(--black)
}

@keyframes arrowDown {
    0% {
        top: 0px;
    }

    100% {
        top: 20px;
    }

}



@keyframes rocketMoon {
    0% {
        left: -110px;
        top: 0px;
    }

    50% {
        left: -110px;
        top: 80px;
    }


    100% {
        left: -110px;
        top: 0px;
    }
}

@keyframes cloudOne {
    0% {
        left: -120px;
        top: 250px;
    }

    50% {
        left: 0px;
        top: 250px;
    }


    100% {
        left: -120px;
        top: 250px;
    }
}

@keyframes cloudTwo {
    0% {
        top: -150px;
        left: 0;
    }

    50% {
        top: -150px;
        left: -20px;
    }


    100% {
        top: -150px;
        left: 0;
    }
}

/*****Super Banner*/



.logoPoliticas {
    margin: 50px 0 150px 0;

}

.logoPoliticas img {
    width: 250px;
}

.nextChevron {
    margin-top: 150px;
}

i.fa-solid.fa-chevron-down {
    font-size: 36px;
    font-weight: bold;
    color: var(--black);
    position: relative;
    animation: arrowDown 1s linear infinite
}

/**********Mision Vision*/
div#misionVision {
    margin-top: 200px;
    background: rgba(255, 255, 255, 0);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 49%, var(--grey) 49%, var(--grey) 100%);

}

#backgrounDots {
    /* background-image: url(../images/landingPoliticas/dots.svg); */
    background-repeat: no-repeat;
    background-position: right;
}

.card {
    padding: 70px;
    min-height: 320px;
    border-radius: 15px;
    box-shadow: 0px 0px 77px rgba(0, 0, 0, 0.06);
}

.card-title {
    font-size: 44px;
}

.card-text {
    font-size: 18px;
}

/**************Politicas*/

#textoPoliticas {
    background-color: var(--grey);
    padding-top: 160px;
    /* background-image: url(../images/landingPoliticas/Circulorayas.svg); */
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-attachment: fixed;
}

#textoPoliticas h2 {
    margin-bottom: 150px;
}

#textoPoliticas h3 {
    font-family: "Mulish", sans-serif;
    font-size: 32px;
    color: var(--black)
}

#textoPoliticas p {
    font-size: 18px;
}

.contentPoliticas {
    border-bottom: 1px solid #DDDDDD;
    padding: 36px;
}

/********************Footer*/

#bigFooter {
    background-color: #fff;
    background-image: url(../assets/policy/isoBlue.png);
    background-position: center;
    background-repeat: no-repeat;
}

.rocket {
    animation: rocketMoon 6s linear infinite;
    position: relative;
}

.cloudOne {
    animation: cloudOne 10s linear infinite;
    position: relative;
}

.cloudTwo {

    position: relative;
    animation: cloudTwo 6s linear infinite;
}


.logoFooter {
    width: 500px;
}

#subFooter {
    border-top: 1px solid #979797;
    padding: 40px;
    margin-top: 40px;
}

#subFooter p {
    font-size: 13px;
}

#bigFooter .nav {
    display: inline-flex;
    margin-bottom: 40px;
}

@media (max-width:767px) {


    @keyframes rocketMoon {
        0% {
            left: -20px;
            top: 0px;
        }

        50% {
            left: -20px;
            top: 80px;
        }


        100% {
            left: -20px;
            top: 0px;
        }
    }

    @keyframes cloudOne {
        0% {
            left: -120px;
            top: 80px;
        }

        50% {
            left: 0px;
            top: 80px;
        }


        100% {
            left: -120px;
            top: 80px;
        }
    }

    @keyframes cloudTwo {
        0% {
            top: -150px;
            left: 40px;
        }

        50% {
            top: -150px;
            left: 0px;
        }


        100% {
            top: -150px;
            left: 40px;
        }
    }

    #politicas {

        background-image: none;
    }

    .mobileImg {
        margin-top: 50px;
    }

    .logoPoliticas {
        margin: 50px 0;
    }

    h1 {
        font-size: 46px;
        text-align: center;
    }

    div#misionVision {
        margin-top: 60px;
    }

    .nextChevron {
        margin-top: 50px;
    }

    .card {
        padding: 30px;
        min-height: auto;
        margin-bottom: 50px;
    }

    #textoPoliticas {
        background-color: var(--grey);
        padding-top: 100px;

    }

    #textoPoliticas h2 {
        margin-bottom: 50px;
    }

    #bigFooter {
        background-color: #fff;
        background-image: url(../assets/policy/isoBlue.png);
        background-position: center 30%;
        background-repeat: no-repeat;
        background-size: 80%;
    }
}
/* Variables de color si el tema es nulo o light */
html {
  --background-color: #fff;
  --background-menu: rgba(245, 245, 245, 0.75);
  --hover-buttons: #FCD702;
  --font-color: #2e2d31;
  --red-color: #006110;
  --title-color: #fff;
  --color-h6: #2e2d31;
  --backgroundImage: url(../assets/faqs/backLigth.png);
  /* --bacgroundFooter: url(); */
  --main-font: "Mulish", sans-serif;
  --secondary-font: "Gotham-Bold", sans-serif;
  --font-size-title: 36px;
  --font-size-menu: 18px;
  --color-text-footer: #2e2d31;
  --color-hr: #0077a3;
  --button-color: #FCD702;
  --radius-button: 8px;
  --color-black: #2e2d31;
}

/* Variables de color si el tema es dark */
html[data-theme="dark"] {
  --background-color: #2e2d31;
  --font-color: #fff;
  --background-menu: rgba(0, 0, 0, 0.75);
  --hover-buttons: rgb(36, 36, 36);
  --color-h6: #fff;
  --title-color: #fff;
  --backgroundImage: url(../assets/faqs/skull.jpg);
  --bacgroundFooter: url(../assets/faqs/darkFooter.jpg);
  --main-font: "Metamorphous", sans-serif;
  --secondary-font: "Metal Mania", sans-serif;
  --font-size-menu: 18px;
  --font-size-title: 24px;
  --color-text-footer: #fff;
  --color-hr: #fff;
  --button-color: #FCD702;
}

html[data-theme="christmas"] {
  --background-color: #4f9767;
  --background-menu: rgba(184, 0, 0, 0.75);
  --hover-buttons: #b80000;
  --font-color: #fff;
  --backgroundImage: url(../assets/faqs/christmas.jpg);
  /* --bacgroundFooter: url(../assets/faqs/christmasFooter.jpg); */
  --bacgroundFooter: url(../assets/faqs/christmasFooterOpc.png);
  --title-color: #2e2d31;
  --color-h6: #fff;
  --main-font: "Mulish", sans-serif;
  --secondary-font: "Mali", cursive;
  --font-size-title: 48px;
  --color-hr: #fff;
  --font-size-menu: 36px;
  --color-text-footer: #2e2d31;
  --button-color: #FCD702;
}

#faqs canvas {
  position: fixed !important;
}

/*VAriables*/

.btn {
  padding: 10px 40px !important;
  border-radius: var(--radius-button);
  border: none;
  font-size: 12px;
}
.btn:disabled{
  background-color: gray;
}
.btn-primary {
  background-color: #2e2d31 !important;
  color: #ffff;
  border-color: #2e2d31;
}
.btn-secondary {
  background-color: transparent;
  border: solid 2px var(--color-black);
  color: var(--color-black);
}

.btn-primary:hover{
  border-color: var(--button-color) !important;
  background-color: var(--button-color) !important;
  color: #2e2d31;
}
.btn-secondary:hover {
  border-color: transparent;
  background-color: var(--button-color);
  color: #2e2d31;
}

#faqs {
  background-color: var(--background-color);
  color: var(--font-color);
  background-image: var(--backgroundImage);
  background-repeat: no-repeat, no-repeat;
  font-family: var(--main-font);
  min-height: 100vh;
  padding-top: 20px;
  background-size: cover;
  background-attachment: fixed;
}

#faqs p {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

#faqs h1 {
  font-family: var(--secondary-font);
  font-size: var(--font-size-title);
  margin-bottom: 30px;
  color: var(--tit);
}

#faqs h2 {
  font-family: var(--secondary-font);
  font-size: 36px;
  margin-bottom: 20px;
}

#faqs a h3 {
  font-weight: 400;
  color: #333f57;
  text-decoration: none;
  font-size: 22px;
  text-align: center;
  justify-content: center;
}
#faqs a h6 {
  font-weight: 400;
  color: #333f57;
  text-decoration: none;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
#faqs a hr {
  color: #333f57;
  border-width: 2.5px;
}

/*******Check Dark Mode*/
#darkMode-switch {
  float: inherit;
  margin-right: 10px;
}

.darkModeContainer {
  margin: 0 0 50px 0;
}

#darkMode-switch:hover {
  cursor: pointer;
}

#darkMode-switch:checked {
  background-color: var(--red-color);
  border-color: var(--red-color);
}

.form-select:focus {
  box-shadow: none;
}

/**************Header*/
#faqs .logo {
  width: 250px;
  margin-bottom: 20px;
}

#faqs ul#navigation {
  position: fixed;
  margin: 0px;
  padding: 0px;
  top: 20vh;
  left: 0px;
  list-style: none;
  z-index: 9999;
}

#faqs ul#navigation li {
  width: 280px;
  margin-left: -235px;
  text-align: right;
  transition: 0.3s all ease;
  color: #fff;

  padding: 15px 15px 15px 0;
  font-size: 20px;
  background-color: var(--grey-backgrounds);
}

#faqs ul#navigation li:hover {
  box-shadow: 0px 3px 5px rgba(100, 100, 100, 0.425);
  border: 1px solid #fff;
  transition: 0.3s all ease;
}

#faqs ul#navigation li a {
  color: #fff;
  font-size: var(--font-size-menu);
}

#faqs #navigation .fa-solid,
#faqs #navigation i.lni {
  margin-left: 20px;
}

#faqs .optionPortals {
  border: 2px solid var(--grey-backgrounds);
  border-top: 2px solid #0077a3;
}

#faqs ul#navigation li.optionPortals:hover {
  margin-left: -140px;
  background-color: #0077a3;
  border: 2px solid #0077a3;
}

#faqs .optionInstitutional {
  border: 2px solid var(--grey-backgrounds);
  border-top: 2px solid #00a351;
}

#faqs ul#navigation li.optionInstitutional:hover {
  margin-left: -100px;
  background-color: #00a351;
  border: 2px solid #00a351;
}

#faqs .optionSgbp {
  border: 2px solid var(--grey-backgrounds);
  border-top: 2px solid #86a300;
}

#faqs ul#navigation li.optionSgbp:hover {
  margin-left: -160px;
  background-color: #86a300;
  border: 2px solid #86a300;
}

#faqs .optionHow {
  border: 2px solid var(--grey-backgrounds);
  border-top: 2px solid #a38600;
}

#faqs ul#navigation li.optionHow:hover {
  margin-left: -100px;
  background-color: #a38600;
  border: 2px solid #a38600;
}

#faqs .optionPower {
  border: 2px solid var(--grey-backgrounds);
  border-top: 2px solid #a34100;
}

#faqs ul#navigation li.optionPower:hover {
  margin-left: 0px;
  background-color: #a34100;
  border: 2px solid #a34100;
}

#faqs .optionDesign {
  border: 2px solid var(--grey-backgrounds);
  border-top: 2px solid #a3009b;
}

#faqs ul#navigation li.optionDesign:hover {
  margin-left: -30px;
  background-color: #a3009b;
  border: 2px solid #a3009b;
}

#faqs .optionHere {
  border: 2px solid var(--grey-backgrounds);
  border-top: 2px solid #0094a1;
}

#faqs ul#navigation li.optionHere:hover {
  margin-left: -80px;
  background-color: #0094a1;
  border: 2px solid #0094a1;
}

#faqs input#floatingInput {
  border: none;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 0;
  margin: 50px 0 100px 0;
  background-color: transparent;
}

#faqs .form-control:focus,
.modal-dialog .form-control:focus {
  border-bottom: 1px solid var(--yellow-possumus) !important;
  box-shadow: none;
}

/*Manu LAteral Icon*/

/* -- Slideout Sidebar -- */

#faqs .slideout-sidebar {
  position: fixed;
  top: 0;
  left: -340px;
  z-index: 2;
  width: 300px;
  height: 100%;
  padding: 20px;
  background: var(--background-menu);
  box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5.1px);
  -webkit-backdrop-filter: blur(5.1px);

  overflow: auto;
  transition: all 300ms ease-in-out;
}

#faqs .slideout-sidebar::-webkit-scrollbar {
  -webkit-appearance: none;
}

#faqs .slideout-sidebar::-webkit-scrollbar:vertical {
  width: 5px;
}

#faqs .slideout-sidebar::-webkit-scrollbar-button:increment,
.slideout-sidebar::-webkit-scrollbar-button {
  display: none;
}

#faqs .slideout-sidebar::-webkit-scrollbar:horizontal {
  height: 10px;
}

#faqs .slideout-sidebar::-webkit-scrollbar-thumb {
  background-color: var(--yellow-possumus);
  border-radius: 5px;
  /* border: 2px solid #f1f2f3; */
}

#faqs .slideout-sidebar::-webkit-scrollbar-track {
  border-radius: 10px;
}

#faqs ul {
  list-style: none;
  padding: 0;
}

#faqs .accordion {
  margin-top: 50px;
}

#faqs button.accordion-button.collapsed {
  background-color: transparent;
}

#faqs h2.accordion-header {
  margin-bottom: 0;
}

#faqs .accordion-item {
  background-color: transparent;
  border: none;
}

.accordion-button:focus {
  box-shadow: none;
}

#faqs .accordion-button {
  cursor: pointer;
  padding: 18px;
  border-bottom: 1px solid var(--yellow-possumus);
  color: rgba(244, 244, 244, 0.7);
  background-image: linear-gradient(
    var(--yellow-possumus),
    var(--yellow-possumus)
  );
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: 0.4s;
  border-radius: 0;
}

#faqs .accordion-button:hover {
  background-size: 100% 100%;
}

#faqs .accordion-button:hover a {
  color: var(--font-color);
}

#faqs .accordion-button a {
  color: var(--font-color);
}

#faqs .accordion-button:not(.collapsed) {
  color: var(--font-color);
  background-color: var(--yellow-possumus);
  /* box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); */
}

.accordion-body {
  background: #f2f2f2;
}

#faqs .accordion-body a {
  display: flex;
}

#faqs .accordion-body a i {
  margin-right: 20px;
}

#faqs .accordion-body a h3,
#faqs .accordion-body a i {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.55);
  transition: var(--transition-3s);
}

#faqs .accordion-body a:hover h3,
#faqs .accordion-body a:hover i {
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  transition: var(--transition-3s);
}

#faqs .accordion-body li {
  margin-bottom: 10px;
}

#faqs .accordion-button::after {
  background-image: url(../assets/faqs/chevron-down-solid.svg);
}

i.fa.fa-bars {
  color: var(--yellow-possumus);
}

.slideout-sidebar ul li:last-child {
  border-bottom: 0;
}

.slideout-sidebar ul li:hover {
  color: rgba(244, 244, 244, 1);
}

/* -- Menu Icon -- */

#menu-toggle {
  display: none;
}

.menu-icon {
  position: fixed;
  top: 18px;
  left: 30px;
  font-size: 24px;
  z-index: 5;
  transition: all 300ms ease-in-out;
}

.menu-icon:hover {
  cursor: pointer;
}

/*-- The Magic --*/
#menu-toggle:checked ~ .slideout-sidebar {
  left: 0px;
}

#menu-toggle:checked + .menu-icon {
  left: 250px;
}

#menu-toggle:checked ~ .content-container {
  padding-left: 190px;
}

/* -- Media Queries -- */

@media (max-width: 991px) {
  .content-container {
    max-width: 480px;
  }
}

@media (max-width: 767px) {
  .content-container {
    max-width: 100%;
    margin: 30px auto 0;
  }
  #faqs .category-list-wrapper .category-list-item .cardStyle {
    width: 289px !important;
    padding: 52px 25px !important;
  }
  .headerFaqs {
    padding-left: 17px !important;
  }
  #menu-toggle:checked ~ .content-container {
    padding-left: 0;
  }

  .slideout-sidebar ul {
    text-align: center;
    max-width: 200px;
    margin: 30px auto 0;
  }

  .menu-icon {
    left: 20px;
  }

  #menu-toggle:checked ~ .slideout-sidebar {
    width: 100%;
  }

  #menu-toggle:checked + .menu-icon {
    left: 87%;
    color: #fafafa;
  }

  @media screen and (max-width: 736px) and (orientation: landscape) {
    .headerFaqs {
      padding-left: 0px !important;
    }
    .slideout-sidebar {
      padding: 0;
    }

    .slideout-sidebar ul {
      max-width: 100%;
      margin: 60px auto 0;
    }

    .slideout-sidebar ul li {
      display: inline-block;
      border-bottom: 0;
      width: 72px;
      padding: 18px 24px;
      margin: 0 6px 12px;
      color: #ffffff;
      background-color: #777;
    }
  }
}

/* demo styles */

.content-wrapper {
  padding-top: 1px;
  box-sizing: border-box;
}

.spec-link {
  color: #fff;
  display: table;
  margin: 100px auto 0;
}

/************Items*/

#faqs #items .row {
  margin-bottom: 50px;
  justify-content: center;
}
.portals {
  margin: 0 10px 0 10px !important;
}

#faqs .category-list-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -15px;
}

#faqs .category-list-wrapper .category-list-item .cardStyle .icon {
  font-size: 35px;
  margin-bottom: 10px;
  line-height: 1;
  color: var(--yellow-possumus);
  transition: var(--transition-3s);
}
#faqs .fixed-button-back {
  position: fixed;
}
/***************Footer*/

#footerFaqs {
  margin-top: 100px;
  padding: 60px 0 20px 0;
  background-image: var(--bacgroundFooter);
  background-size: cover;
}
#footerFaqs .christmasIlustration{
  position: fixed;
  bottom: 0;
  left: 5%;
  width: 250px;
}

#footerFaqs p {
  padding: 0;
  margin: 0;
  color: var(--color-text-footer);
}

#footerFaqs a {
  transition: var(--transition-3s);
  color: #2e2d31;
  font-weight: bold;
}

#footerFaqs a:hover {
  color: var(--yellow-possumus);
}
.widthCard {
  width: 400px !important;
}
.textBold {
  font-weight: bold;
}
#faqs .category-list-wrapper .category-list-item .cardStyle {
  display: block;
  text-align: center;
  width: 220px;
  background: #f2f2f2;
  color: #2e2d31;
  border-radius: 5px;
  padding: 40px 25px;
  margin: 15px;
  border: 1px solid rgba(124, 134, 154, 0.25);
  box-shadow: 0px 3px 5px rgb(218 222 228 / 30%);
  transition: all 0.3s ease-in;
  min-height: 200px;
}
#faqs .category-list-wrapper .category-list-item button h3 {
  font-size: 22px;
}
.colorButton {
  background-color: var(--button-color);
  border-color: #fff;
}
.colorButton:hover {
  background-color: #0077a3;
  transition: all 0.3s ease-in;
}
.linkStyle {
  margin-top: 20px;
}

/********************Should I Deploy*/

.modal-dialog {
  max-width: 70%;
}

.modal-dialog .form-floating > label {
  color: #000 !important;
}

.modal-dialog .form-control:focus,
.modal-dialog .form-control:focus {
  border-bottom: 1px solid #e3e3e3 !important;
}

.modal-content {
  text-align: center;
}

.modal-title.h4 {
  font-size: 50px;
  color: var(--color-black);
}

.modal-dialog input#floatingInput {
  border: none;
  border-bottom: 1px solid var(--color-black);
  border-radius: 0;
  background-color: transparent;
}

#checkDeploy {
  margin-top: 20px;
}

.modal-header {
  border-bottom: none;
}

.modal-body {
  padding-top: 100px;
  padding-bottom: 100px;
}
.messageDeploy {
  text-transform: uppercase;
  font-size: 60px;
  font-weight: bold;
}

#checkDeployForm {
  margin: 50px 0;
}

#deployOk .messageDeploy,
#deployError .messageDeploy {
  color: #fff;
}

#deployOk .modal-title.h4,
#deployError .modal-title.h4 {
  font-size: 20px;
  color: #fff;
}

.modal-content {
  color: #fff !important;
  background-image: url(../assets/faqs/backModal.jpg);
  background-size: cover;
}

#deployOk .modal-content {
  background-image: url(../assets/faqs/backModalOk.jpg);
}

#deployError .modal-content {
  background-image: url(../assets/faqs/backModalError.jpg);
}

.noUrlMessage {
  margin: 50px 0 0 0;
}

.noUrlMessage p {
  margin: 20px 0;
}

.category-list-item a:hover {
  cursor: pointer;
}
